import React, { useEffect, useState } from 'react'
import "./Footer.scss"

const Footer = () => {
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentYear(new Date().getFullYear());
    }, 1000 * 60 * 60); // Update every hour

    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className='footer'>
        <div className='footer-cont center'></div>
        <div>
            <p>E-mail: onlinegamblegames@gmail.com</p>
            <p>Fruit Party © {currentYear}</p>
        </div>
    </div>
  )
}

export default Footer