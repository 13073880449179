import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.scss";
import AboutPage from "./page/AboutPage";
import HomePage from "./page/HomePage";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route>
            <Route path="/" element={<HomePage />} />
            {/* <Route path="/about" element={<AboutPage />} /> */}
          
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
