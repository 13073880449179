import React from "react";
import "./Slot.scss";
import img1 from "../../assets/chrome_fd1pG3OnQg.jpg";
import img2 from "../../assets/chrome_5TOwbYXAVK.png";
import img3 from "../../assets/fruit-party-sluchaynye-mnozhiteli.png";
import img4 from "../../assets/fruit-party-bolshe-mnozhiteley-v-bonusnoy-igre.png";
import img5 from "../../assets/chrome_ZB2MbqXhu4.png";
import img6 from "../../assets/chrome_HtoDvwzXoj.png";
import img7 from "../../assets/fruit-party-scatter-simvol.png";
import img8 from "../../assets/chrome_6XmcnI5ayN.png";

const Slot = () => {
  return (
    <div className="slot ">
      <div className="slot-cont center">
        <h1>FRUIT PARTY SLOT</h1>
        <p>
          Get ready for an outdoor fruit party! The Pragmatic Play provider has
          covered a 7 by 7 clearing of fresh fruits for you. Thanks to an
          incredibly juicy 96.47% RTP and a maximum 5000x win from the bet, you
          will definitely not stay hungry!
        </p>
      </div>
      <div className="promoc center">
        <p>PROMOCODE</p>
        <p>Pragmatic2023</p>
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
          <button className="button-22">PLAY FOR MONEY</button>
        </a>
        <p>BONUS 500% on deposit</p>
        <p>+70 free spins</p>
        <p>+1000 dollars for app instalation</p>
      </div>
      <div className="maininfo center">
        <a href="https://catchthecatkz.com/d467ed7a5" target="_blank">
          <button className="button-22">PLAY DEMO</button>
        </a>
        <h2>MAIN INFROMATION</h2>
        <div classname="table">
          <div className="table-cont center">
            <table>
              <tr>
                <th>PROVIDER</th>
                <th>PRAGMATIC PLAY</th>
              </tr>
              <tr>
                <th>RELEASE DATE</th>
                <th>2020</th>
              </tr>
              <tr>
                <th>RTP</th>
                <th>96,47%</th>
              </tr>
              <tr>
                <th>MAX. WINNING</th>
                <th>5,000x</th>
              </tr>
              <tr>
                <th>VOLATILITY</th>
                <th>HIGHT</th>
              </tr>
              <tr>
                <th>MIN. BET</th>
                <th>1$</th>
              </tr>
              <tr>
                <th>MAX. BET</th>
                <th>100$</th>
              </tr>
              <tr>
                <th>FREE SPINS</th>
                <th>AVAILABLE</th>
              </tr>
              <tr>
                <th>BUYING A BONUS</th>
                <th>AVAILABLE</th>
              </tr>
              <tr>
                <th>RETRIGGER</th>
                <th>AVAILABLE</th>
              </tr>
              <tr>
                <th>TURBO MODE</th>
                <th>AVAILABLE</th>
              </tr>
              <tr>
                <th>DROPS&WINS</th>
                <th>AVAILABLE</th>
              </tr>
            </table>
          </div>
          <h2>How much I can win in FRUIT PARTY?</h2>
          <p>
            Fruit Party slot can please the player with a juicy skid not only in
            the bonus game, but also in the regular one. Thanks to the random
            multiplier, only one spin in the main game is enough to break the
            maximum win of 5,000x.
          </p>
          <h2>BIG WIN FROM SPIN</h2>
        </div>
        <img src={img1} alt="img" />
        <h2>BIG WIN WITH BONUS BUY</h2>
        <img src={img2} alt="img" />
        <h2>UNIQUE FEATURES IN FRUIT PARTY SLOT</h2>
        <h2>RANDOM MULTIPLIERS</h2>
        <p>
          During the main game, each symbol from the winning block can receive a
          2x multiplier. The total winnings of the block are multiplied by all
          the multipliers involved in it, a maximum of 256x. In the picture
          below, you can see the breakdown of the maximum win of x5000. 15
          asterisk symbols give x40, and the total multiplier is x256. And this
          is from a single spin in the main game!
        </p>
        <img className="smallimg" src={img3} alt="img" />
        <p className="middletext">x40 * x256 = x10,240</p>
        <h2>MORE MULTIPLIERS IN BONUS GAME</h2>
        <p>
          In the bonus round of the Fruit Party slot, another 4x is added to the
          2x multiplier. There are more multipliers themselves, and it is easier
          to knock them out. The maximum total multiplier for one winning
          cluster is the same — 256x.
        </p>
        <img className="smallimg" src={img4} alt="img" />
        <h2>RETRIGERR FUNCTION</h2>
        <p>
          If you are lucky enough to catch 3 or more scatters in the bonus game,
          you will receive additional free spins:
        </p>
        <div className="scatter-freespin">
          <li>7 scatters gives +14 freespins</li>
          <li>6 scatters gives +13 freespins</li>
          <li>5 scatters gives +12 freespins</li>
          <li>4 scatters gives +11 freespins</li>
          <li>3 scatters gives +10 freespins</li>
        </div>
        <img className="smallimg" src={img5} alt="" />
        <h2>BUYING FREE SPINS</h2>
        <p>
          Do you like bonus rounds, but don't want to wait for them to fall out?
          You can always buy an instant launch of the free spins round for 100x
          of your bet.
        </p>
        <img className="smallimg" src={img6} alt="img" />
        <h2>SYMBOLS</h2>
        <div className="scatter">
          <img src={img7} alt="img" />
          <p>Scatter - symbol</p>
        </div>
        <p>
          The loss of 3 or more scatters in the main game triggers a bonus round
          with 10 free spins. Collect 3 or more scatters in the bonus game and
          you will get additional free spins.
        </p>
        <p>
          The loss of 3 or more scatters in the main game triggers a bonus round
          with 10 free spins. Collect 3 or more scatters in the bonus game and
          you will get additional free spins.
        </p>
        <img className="smallimg" src={img8} alt="img" />
        <p className="middletext">The payout table for the 160 bet</p>
        <h2>FAQ — FREQUENTLY ASKED QUESTIONS</h2>
        <h3>Who is the FRUIT PARTY provider?</h3>
        <p>The Fruit Party game was released by Pragmatic Play in May 2020.</p>
        <h3>What is the max win?</h3>
        <p>5,000x from the ber</p>
        <h3>What is the RTP on FRUIT PARTY slot machine</h3>
        <p>
          The standard RTP value for this game is 96.47%. However, the
          percentage of return may vary slightly depending on the online casino
          where you play. You can always see the exact value in the help for the
          game.
        </p>
        <h3>Is it worth to buy bonuses?</h3>
        <p>
          The percentage of return in the Fruit Party slot does not depend on
          whether you buy a bonus or not. Therefore, you can safely buy it and
          not worry about the reduced RTP, as in some other slots.
        </p>
        <h3>Is there function to buy bonus round?</h3>
        <p>
          Yes, you can buy an instant launch of the bonus feature with 10 free
          spins for 100x of the bet amount.
        </p>
        <h3>How volatile is the Fruit Party slot?</h3>
        <p>
          This machine has a high level of risk. This means that for a long time
          you can not win anything, and then suddenly bring in 5,000 c per spin
          in the main game, catching a clearing with multipliers. Slots with low
          volatility, on the contrary, are given often, but little by little.
        </p>
      </div>
    </div>
  );
};

export default Slot;
