import React, { useEffect, useState } from "react";
import logo from "../../assets/fruit-party-slot-logo.png";
import "./Header.scss";

const Header = () => {
  const localStorageKey = "randomNumber";

  const [randomNumber, setRandomNumber] = useState(() => {
    const storedNumber = localStorage.getItem(localStorageKey);
    const parsedNumber = storedNumber ? parseInt(storedNumber, 10) : null;
    return parsedNumber !== null ? parsedNumber : generateRandomNumber();
  });

  function generateRandomNumber() {
    const newRandomNumber =
      Math.floor(Math.random() * (700000 - 300000 + 1)) + 300000;
    localStorage.setItem(localStorageKey, newRandomNumber.toString());
    return newRandomNumber;
  }

  useEffect(() => {
    const intervalId = setInterval(() => {
      generateRandomNumber();
    }, 24 * 60 * 60 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <header>
      <div className="header">
        <div className="header-cont center">
          <div>
            <p>
              The maximum win in the Fruit Party for yesterday - ${randomNumber}
            </p>
          </div>

        </div>
        <div>
          <a href="/">
            <img src={logo} alt="logo" />
          </a>
          {/* <a href="/about">About Fruit Party</a>
          <a href="/description">Description</a> */}
        </div>
      </div>
    </header>
  );
};

export default Header;
